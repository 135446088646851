<template>
    <div class="product-filter card p-1">
      <div class="card-header">{{$t('product-page.prices')}} : </div>
      <div class="card-body">
        <price-one
          v-for="(item, index) in roomsList"
          :key="index"
          :data="item"
          :index="index"
          :supplier="supplier"
          :hotels="totalHotelList[index]"
          :date="daysString"
          @changedRoomType="triggerChangeRoomType"
          @closed="removeItem"
          @update="updateItem"
          @setPriceLoading="getPriceLoading"
        />
        <div class="formBody">
          <div class="button-area">
            <button type="button" class="btn btn-primary" @click="addRoom" :disabled="addRoomDisable || priceLoading">
              <b-spinner class="ml-3" small type="grow" v-if="priceLoading"></b-spinner>{{ $t("product-page.add-room") }}
            </button>
          </div>
          <div class="note-tag" v-if="adminRemark">
            <strong>{{ $t("product-page.remarks")}}</strong> : {{adminRemark}}
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="date">
          <h5>{{ $t("product-page.vacation-price") }}</h5>
        </div>
        <price-two
          v-for="(item, index) in roomsList"
          :key="index"
          :room="item"
          :index="index"
          @closed="removeItem"
        />
        <div class="card-footer">
          <div class="d-flex justify-content-between">
            <div class="p-2">{{ $t("product-page.special-price") }}</div>
            <div class="p-2">₪{{ totalPrice }}</div>
          </div>
        </div>
        <div class="d-flex justify-content-between w-100 button-area">
          <span class="remark" v-if="remark!==''">
            {{ remark }}
          </span>
          <button class="btn btn-primary" :disabled="priceLoading" @click="gotoBook">
            {{ $t("product-page.book-button") }}
          </button>
        </div>
      </div>
    </div>
</template>
<script>
import { BSpinner } from 'bootstrap-vue';
import TemplatePriceArea from './TemplatePriceArea';

export default {
  name: 'PriceAreaTheme1',
  mixins: [TemplatePriceArea],
  components: {
    BSpinner,
    PriceOne: () => import('@/components/productPage/priceOne/PriceOneTheme1'),
    PriceTwo: () => import('@/components/productPage/priceTwo/PriceTwoTheme1'),
  },
};
</script>
<style scoped>
.product-filter.card .card-body .formBody .button-area {
    padding: 12px 0;
}
.product-filter.card .card-body .formBody .button-area .btn {
    padding: 7px 18px;
    font-size: 15px;
    font-weight: 400;
}
.product-filter.card .card-body .formBody .button-area .btn-primary{
    border-color: #23BEDC;
    background-color: #23BEDC;
    color: #ffffff;
}
.product-filter.card .card-body .formBody .button-area .btn-primary:focus{
    box-shadow: none;
    border-color: #000000;
    background-color: #000000;
}
.product-filter.card .card-body .formBody .note-tag{
    color: #000000;
    font-size: 12px;
    font-weight: 300;
    font-style: italic;
}
.product-filter.card .card-body .formBody .note-tag strong{
    font-weight: 500;
}
.product-filter.card .card-body{
    border: 1px solid #dfdfdf;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 15px;
}
.product-filter.card .card-footer{
  padding: 0px 1.25rem;
  background-color: #E4F8FC;
}
.product-filter.card{
    margin-bottom: 24px;
}
.product-filter.card .card-body .button-area {
    padding: 12px 0;
}
.product-filter.card .card-body .button-area .btn-primary {
    border-color: #23BEDC;
    background-color: #23BEDC;
    color: #ffffff;
}
.product-filter.card .card-body .button-area .btn-primary:focus {
    box-shadow: none;
    border-color: #000000;
    background-color: #000000;
}
.product-filter.card .card-body .button-area .btn {
    padding: 7px 18px;
    font-size: 15px;
    font-weight: 400;
}

</style>
